import "animate.css";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { NextPageContext } from "next";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/Layout";
import { getMultiTenentMeta } from "../utils/meta";

import { useOrgTheme } from "../context/ThemeContext";
import { getPageContent } from "../utils/contentFetcher";
import { OrganisationTheme } from "../interfaces/Theme";
import dynamic from "next/dynamic";

const Home = dynamic(() => import("../components/Home/index"));
const ClientOrgHome = dynamic(() => import("../components/Home/Client"));

export type HomePageContent = Pick<
  OrganisationTheme,
  | "htmlContent"
  | "cssVariables"
  | "trustedPartners"
  | "orgId"
  | "hostNameId"
  |"is_preview"
  |"orgName"
> & {
  isClient: boolean;
  homePageContent: OrganisationTheme["pageContent"]["homepageContent"];
};

const IndexPage = ({ content }: { content: HomePageContent }) => {
  const { isClientOrg, getOrgSlug, sethostname } = useOrgTheme();

  const homeContent = useMemo(() => {
    if (content?.orgId) {
      return content;
    }
  }, [content]);

  useEffect(() => {
    if (homeContent?.orgId) {
      sethostname({
        id: homeContent.hostNameId,
        orgId: homeContent.orgId,
        preview: homeContent.is_preview,
      });
    }
  }, [homeContent]);

  const getHomeContent = useCallback(() => {
    if (isClientOrg) {
      return <ClientOrgHome content={content} />;
    } else if (getOrgSlug() === "solarquote") {
      return <Home />;
    } else {
      return <></>;
    }
  }, [homeContent, isClientOrg]);

  const orgName=content?.orgName || "";
  const MemoizedHomeContent = useMemo(() => memo(getHomeContent),[getHomeContent]);

  const indexPageMetaTagsContent = useMemo(
    () => getMultiTenentMeta(orgName, "index"),
    [orgName]
  );
  
  return (
    <Layout
      title={indexPageMetaTagsContent.title}
      description={indexPageMetaTagsContent.description}
      keywords={indexPageMetaTagsContent.keywords}
    >
      <MemoizedHomeContent />
      <ToastContainer position="bottom-center" autoClose={8000} />
    </Layout>
  );
};

export default memo(IndexPage);

export async function getServerSideProps(ctx: NextPageContext) {
  const res = await getPageContent("home", ctx);
  return {
    props: {
      content: res.data,
    },
  };
}
